<template>
  <div class="container w-full mx-auto py-10 px-4 lg:px-0 flex flex-col">
    <div class="flex flex-col items-center justify-center lg:flex-row">
      <div
        class="
          lg:w-1/2
          w-full
          p-0
          lg:p-28 lg:pt-20
          self-start
          lg:mb-0
          mb-10
          text-center
          lg:text-left
        "
      >
        <h1
          class="
            font-bold
            uppercase
            tracking-tight
            text-2xl text-webmoney
            dark:text-blue-400
            mb-4
          "
        >
          {{ $t("ext.uninstall.title") }}
        </h1>
        <p class="text-xl text-gray-500 dark:text-gray-400 mb-10">
          {{ $t("ext.uninstall.subtitle") }}
        </p>
        <DownloadExt />
      </div>

      <form
        class="
          lg:w-1/2
          w-full
          p-5
          m-0
          lg:m-16
          border
          rounded
          dark:border-gray-700
        "
        @change="formChange"
        v-if="!requestSucces"
        @submit="sendAnswer"
      >
        <h3 class="text-webmoney dark:text-blue-400 text-xl font-bold mb-8">
          {{ $t("ext.uninstall.form.title") }}
        </h3>

        <label
          v-for="(item, index) in questions"
          :key="index"
          :for="'item-' + item.id"
        >
          <input
            type="radio"
            name="formRadio"
            v-model="reason_id"
            :id="'item-' + item.id"
            :value="item.id"
            class="mb-8"
            :placeholder="item[`description_${currentLocale}`]"
          />
          {{ item[`title_${currentLocale}`] }}
          <br />
        </label>

        <textarea
          name="text"
          id=""
          cols="40"
          rows="3"
          class="
            mb-3
            py-2
            px-4
            block
            border
            rounded
            border-gray-300
            focus:outline-none focus:ring-1 focus:border-webmoney
            dark:focus:border-blue-400
            w-full
            dark:bg-gray-700 dark:border-gray-500
          "
          :placeholder="textareaPlaceholder"
          v-model="description"
        ></textarea>

        <button
          type="submit"
          class="
            inline-block
            uppercase
            bg-webmoney
            text-white
            px-5
            py-2
            rounded
            hover:bg-blue-400 hover:text-white
            shadow
            text-sm
          "
        >
          {{ $t("ext.uninstall.form.submit") }}
        </button>
      </form>
      <div
        v-else
        class="
          lg:w-1/2
          w-full
          p-10
          m-0
          lg:m-16
          border
          dark:border-gray-700
          rounded
        "
      >
        <h2
          class="
            font-bold
            uppercase
            tracking-tight
            text-2xl text-webmoney
            dark:text-blue-400
            mb-4
          "
        >
          {{ $t("ext.uninstall.thanks") }}
        </h2>
        <p>
          {{ $t("ext.uninstall.thanks_description") }}
        </p>
      </div>
    </div>

    <TrustedExt />
  </div>
</template>

<script>
import DownloadExt from "@/components/DownloadExt.vue";
import TrustedExt from "@/components/TrustedExt.vue";
import uninstallApi from "@/api/uninstall";
import { Trans } from "@/plugins/Translation";

export default {
  components: {
    DownloadExt,
    TrustedExt
  },
  data() {
    return {
      textareaPlaceholder: "",
      questions: [],
      reason_id: null,
      description: null,
      uuid: null,
      requestSucces: false
    };
  },
  mounted() {
    uninstallApi.getQuestions().then(data => {
      this.questions = data;
    });
    this.uuid = this.$route.query.uuid;
  },
  computed: {
    currentLocale() {
      return Trans.currentLocale;
    }
  },
  methods: {
    formChange(e) {
      this.textareaPlaceholder = e.target.placeholder;
    },
    sendAnswer() {
      if (this.uuid) {
        uninstallApi.sendAnswer(this.uuid, this.reason_id, this.description);
      }
      this.requestSucces = true;
    }
  }
};
</script>
