import axios from "axios"

const api_root = process.env.VUE_APP_API_URL

export default {
    getQuestions() {
        const url = `${api_root}/uninstall_reasons`;
        return axios.get(url, { withCredentials: true })
            .then(res => {
                return res.data.data
            })
    },
    sendAnswer(uuid, reason_id, description) {
        const url = `${api_root}/uninstallations/set_reason`;
        const data = {
            uuid,
            reason_id,
            description
        }
        return axios.post(url, data, { withCredentials: true }).then(res => {
            return res.data
        })
    },
    

}